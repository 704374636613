import htmlIcon from "./Icons/html.png";
import cssIcon from "./Icons/css.png";
import pythonIcon from "./Icons/python.png";
import reactIcon from "./Icons/react.png";
import javascriptIcon from "./Icons/javascript.png";
import nodejsIcon from "./Icons/nodejs.png";
import phpIcon from "./Icons/php.png";
import laravelIcon from "./Icons/laravel.png";
import djangoIcon from "./Icons/django.png";
import androidStudioIcon from "./Icons/android-studio.png";
import flutterIcon from "./Icons/flutter.png";
import mongodbIcon from "./Icons/mongodb.png";
import mysqlIcon from "./Icons/mysql.png";
import tensorflowIcon from "./Icons/tensorflow.png";
import useIntersectionObserver from "../../../Functions/IntersectionObserver";

// Array of icon data
const icons = [
  { src: htmlIcon, alt: "HTML" },
  { src: cssIcon, alt: "CSS" },
  { src: javascriptIcon, alt: "JavaScript" },
  { src: reactIcon, alt: "React" },
  { src: pythonIcon, alt: "Python" },
  { src: tensorflowIcon, alt: "TensorFlow" },
  { src: nodejsIcon, alt: "Node.js" },
  { src: phpIcon, alt: "PHP" },
  { src: laravelIcon, alt: "Laravel" },
  { src: djangoIcon, alt: "Django" },
  { src: androidStudioIcon, alt: "Android Studio" },
  { src: flutterIcon, alt: "Flutter" },
  { src: mongodbIcon, alt: "MongoDB" },
  { src: mysqlIcon, alt: "MySQL" },
];

function FeatureTechnologies() {
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <section id="Technologies">
      <div className="max-w-screen-xl mx-auto mt-10 mb-10 px-5 bg-primaryBackgoundColor">
        <div className="mt-16 md:mt-0">
          <h2
            ref={titleRef}
            className={`text-4xl lg:text-4xl text-primaryFontColor font-bold lg:tracking-tight ${
              isTitleVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            Versatile Technology Stack
          </h2>
          <p
            ref={descRef}
            className={`text-lg mt-4 text-secondaryFontColor ${
              isDescVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            Specializing in web and mobile development, we deliver tailored
            solutions using the latest technologies. Your vision, expertly
            executed.
          </p>
        </div>

        <div className="mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-7">
          {icons.map((icon, index) => (
            <Technology techIcon={icon} key={index} />
          ))}
        </div>
        <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
      </div>
    </section>
  );
}

const Technology = (props) => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <div
        ref={ref}
        key={props.key}
        className={`p-4 grayscale transition duration-200 hover:grayscale-0 ${
          isVisible ? "animate-fade-up" : "opacity-0"
        }  hover:animate-bounce hover:animate-infinite`}
      >
        <img
          src={props.techIcon.src}
          className="h-20 w-auto mx-auto"
          loading="lazy"
          alt={props.techIcon.alt}
        />
      </div>
    </>
  );
};

export default FeatureTechnologies;
