import ScrollToTop from "react-scroll-up";
import { FaArrowUp } from "react-icons/fa6";

function ScrollUp() {
  return (
    <>
      <ScrollToTop showUnder={160}>
        <div class="relative inline-flex  group">
          <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
          <span className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-primaryFontColor transition-all duration-200 bg-primaryBackgoundColor font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
            <FaArrowUp />
          </span>
        </div>
      </ScrollToTop>
    </>
  );
}

export default ScrollUp;
