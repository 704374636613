import useIntersectionObserver from "../../../Functions/IntersectionObserver";

import teamImage from  "./TeamImage.jpg"

let companyInformation = [
  {
    title: "33+ Years",
    desc: "Influencing Digital Landscapes Together",
  },
  {
    title: "125+ Projects",
    desc: "Excellence Achieved Through Success",
  },
  {
    title: "26+ Awards",
    desc: "Our Dedication to Innovation Wins Understanding",
  },
  {
    title: "99% Happy Clients",
    desc: "Mirrors our Focus on Client Satisfaction",
  },
];

function AboutCompany() {
  // Animate on Scroll Help
  const [imageRef, isImageVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  const [companyDetailsRef, isCompanyDetailsVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <section className="max-w-screen-xl mx-auto mt-10 mb-10 px-5">
        <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div className="w-full justify-start items-center xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
            <div
              ref={companyDetailsRef}
              className={`w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex ${
                isCompanyDetailsVisible ? "animate-fade-right" : "opacity-0"
              }`}
            >
              <div className="w-full flex-col justify-center items-start gap-8 flex">
                <div className="flex-col justify-start lg:items-start items-center gap-4 flex">
                  <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                    <h2 className="text-primaryFontColor text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                      The Tale of Our Achievement Story
                    </h2>
                    <p className="text-secondaryFontColor text-base font-normal leading-relaxed lg:text-start text-center">
                      Our achievement story is a testament to teamwork and
                      perseverance. Together, we've overcome challenges,
                      celebrated victories, and created a narrative of progress
                      and success.
                    </p>
                  </div>
                </div>
                <div className="w-full flex-col justify-center items-start gap-6 flex">
                  <div className="w-full justify-start items-center gap-4 grid md:grid-cols-2 grid-cols-2">
                    {companyInformation.map((value, index) => {
                      return (
                        <div className="w-full h-full p-3.5 rounded-xl hover:border-2 hover:border-secondaryFontColor animated-border flex-col justify-start items-start gap-2.5 bg-secondaryBackgroundColor inline-flex">
                          <h4 className="text-primaryFontColor text-2xl font-bold font-manrope leading-9">
                            {value.title}
                          </h4>
                          <p className="text-secondaryFontColor hover:text-primaryFontColor text-base font-normal leading-relaxed">
                            {value.desc}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={imageRef}
              className={`w-full lg:justify-start justify-center items-start flex ${
                isImageVisible ? "animate-fade-left" : "opacity-0"
              }`}
            >
              <div className="sm:w-[564px] w-full sm:h-[646px] h-full sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
                <img
                  className="w-full h-full rounded-3xl object-cover"
                  src={teamImage}
                  alt="About Us"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
      </section>
    </>
  );
}

export default AboutCompany;
