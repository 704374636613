import { useState } from "react";
import useIntersectionObserver from "../../../Functions/IntersectionObserver";

// FAQ => Questions and Answers
const faqs = [
  {
    question: "What services does your software company provide?",
    answer:
      "Our company offers a comprehensive range of software development services, specializing in creating custom mobile and web applications tailored to meet the unique needs of our clients. We leverage the latest technologies and industry best practices to deliver scalable, secure, and high-performance solutions that drive business success.",
  },
  {
    question: "How can potential clients contact your company for inquiries?",
    answer:
      "Potential clients can reach out to us through multiple channels for inquiries. They can contact us via phone for immediate assistance, send us an email for detailed queries, or visit our website to fill out our contact form. Our team is committed to providing prompt and helpful responses to all inquiries.",
  },
  {
    question: "What is the pricing structure for your services?",
    answer:
      "The pricing for each project is determined based on its complexity and the time required for completion. As such, we do not have a fixed pricing structure. We provide customized quotes for each project after a thorough assessment of the client's requirements and project scope.",
  },
  {
    question: "Do you offer custom software development solutions?",
    answer:
      "Yes, we offer custom software development solutions across a wide range of technologies. Our expertise encompasses web and mobile development, as well as advanced fields such as artificial intelligence (AI). We tailor our solutions to meet the specific needs and objectives of our clients, ensuring high-quality and innovative outcomes.",
  },
  {
    question: "What is the typical turnaround time for a project?",
    answer:
      "The turnaround time for a project varies depending on its scope and complexity. However, we ensure that our clients are kept informed about the progress of their projects through regular updates. We prioritize transparent communication to provide a clear understanding of the project timeline and any milestones achieved.",
  },
];

function FaqSection() {
  // Current Toggle FAQ
  let [current, setCurrent] = useState(-1);

  // Handle the Faq Selection
  let handleFAQToggle = (index) => {
    if (index !== current) {
      setCurrent(index);
    } else {
      setCurrent(-1);
    }
  };

  // Animate on Scroll Help
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <section id="FAQ">
        <div
          ref={ref}
          className={`max-w-screen-xl mx-auto mt-10 mb-10 px-5 text-primaryFontColor ${
            isVisible ? "animate-fade-up" : "opacity-0"
          }`}
        >
          <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center lg:mb-8 lg:text-3xl">
            Frequently asked questions
          </h2>
          <div className="max-w-screen-md mx-auto">
            <div id="accordion-flush" data-accordion="collapse">
              {faqs.map((faq, index) => (
                <div key={index}>
                  <h3 id={`accordion-flush-heading-${index + 1}`}>
                    <button
                      onClick={() => {
                        handleFAQToggle(index);
                      }}
                      type="button"
                      className="flex items-center justify-between w-full py-5 font-medium text-left border-b"
                    >
                      <span className={current !== index ? "" : "font-bold"}>
                        {faq.question}
                      </span>
                      <svg
                        data-accordion-icon=""
                        className={`w-6 h-6 shrink-0 ${
                          current !== index ? "" : "rotate-180"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </h3>
                  <div className={current !== index ? "hidden" : "animate-fade-down"}>
                    <div className="py-5 border-b text-secondaryFontColor">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqSection;
