import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function ChatWidget() {
  let propertyID = "66e5c8d350c10f7a00aa1025";
  let widgetID = "1i7oph0nd";
  return (
    <>
      <TawkMessengerReact propertyId={propertyID} widgetId={widgetID} />
    </>
  );
}

export default ChatWidget;
