import useIntersectionObserver from "../../../Functions/IntersectionObserver";
let reviews = require("./Reviews.json");

function TestimonialSection() {
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <section id="testimonies">
      <div className="max-w-screen-xl mx-auto mt-10 mb-10 px-5 bg-primaryBackgoundColor">
        <div className="mt-16 mb-16 md:mt-0">
          <h2
            ref={titleRef}
            className={`text-4xl lg:text-4xl text-primaryFontColor font-bold lg:tracking-tight ${
              isTitleVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            Innovative Problem Solvers
          </h2>
          <p
            ref={descRef}
            className={`text-lg mt-4 text-secondaryFontColor ${
              isDescVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            We craft creative, effective solutions to complex challenges,
            ensuring your project’s success from start to finish. Your goals,
            our commitment.
          </p>
        </div>

        <Reviews reviews={reviews} />
        <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
      </div>
    </section>
  );
}

// ReviewItem component to render each review
const ReviewItem = ({ review }) => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <li className="text-sm leading-6">
      <div className="relative group">
        <div
          ref={ref}
          className={`relative space-y-2 bg-secondaryBackgroundColor p-5 rounded-xl hover:border-2 hover:border-secondaryFontColor ${
            isVisible ? "animate-fade-left" : "opacity-0"
          } animated-border`}
        >
          <div className="flex items-center space-x-4">
            <img
              src={require(`./Images/${review.image}`)}
              className="w-12 h-12 bg-center bg-cover border rounded-full"
              alt={review.name}
            />
            <div>
              <h3 className="text-lg font-semibold text-primaryFontColor">
                {review.name}
              </h3>
              <p className="text-gray-500 text-md text-secondaryFontColor">
                {review.title}
              </p>
            </div>
          </div>
          <p className="leading-normal text-gray-300 text-md text-secondaryFontColor hover:text-primaryFontColor">
            {review.text}
          </p>
        </div>
      </div>
    </li>
  );
};

// Reviews component to render the list of reviews
const Reviews = ({ reviews }) => {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
      {reviews.map((column, index) => (
        <ul
          key={index}
          className={`space-y-8 ${index === 1 ? "hidden sm:block" : ""} ${
            index === 2 ? "hidden lg:block" : ""
          }`}
        >
          {column.map((review, idx) => (
            <ReviewItem key={idx} review={review} />
          ))}
        </ul>
      ))}
    </div>
  );
};

export default TestimonialSection;
