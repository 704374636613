import HomeHeader from "../Components/Sections/HomeHeader/HomeHeader";
import HomeFeatures from "../Components/Sections/HomeFeatures/HomeFeatures";
import FeatureTechnologies from "../Components/Sections/FeatureTechnologies/FeatureTechnologies";
import FaqSection from "../Components/Sections/FAQ/FAQ";
import { useEffect } from "react";
import TestimonialSection from "../Components/Sections/Testimonials/Testimonial";

function HomePage() {
  // Configuration on document load
  useEffect(() => {
    document.title = "Home - DinsoSoft";
  }, []);

  return (
    <>
      <HomeHeader />
      <HomeFeatures />
      <FeatureTechnologies />
      <TestimonialSection />
      <FaqSection />
      
    </>
  );
}

export default HomePage;
