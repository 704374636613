import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import EstimatePage from "./Pages/EstimatePage";
import HomePage from "./Pages/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutPage from "./Pages/LayoutPage";

function App() {
  return (

    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LayoutPage />}>
            <Route index element={<HomePage />} />
            <Route path="About" element={<AboutPage />} />
            <Route path="Contact" element={<ContactPage />} />
            <Route path="Estimate" element={<EstimatePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
