import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import NavigationBar from "../Components/NavigationBar/NavigationBar";
import ScrollUp from "../Components/ScrollUp/ScrollUp";
import ChatWidget from "../Components/ChatWidget/ChatWidget";
import AnimatedCursor from "react-animated-cursor";

function LayoutPage() {
  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: "#b3b3b3",
        }}
        outerStyle={{
          border: "3px solid #b3b3b3",
        }}
      />
      <NavigationBar />
      <Outlet />
      <Footer />
      <ScrollUp />
      <ChatWidget />
    </>
  );
}

export default LayoutPage;
