import useIntersectionObserver from "../../Functions/IntersectionObserver";

function CallToAction() {
  // Handle On Scroll Animations
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [buttonRef, isButtonVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <section>
        <div className=" mx-auto max-w-screen-xl">
          <div className="max-w-screen-xl mx-auto mt-10 mb-10 px-5 bg-primaryBackgoundColor text-center">
            <h2
              ref={titleRef}
              className={`mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white ${
                isTitleVisible ? "animate-fade-left" : "opacity-0"
              }`}
            >
              Let’s bring your ideas to life – Contact Us Today!
            </h2>
            <p
              ref={descRef}
              className={`mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg ${
                isDescVisible ? "animate-fade-up" : "opacity-0"
              }`}
            >
              Unlock the potential of your business with custom software
              solutions designed to meet your unique needs
            </p>

            <a
              ref={buttonRef}
              href="/Contact"
              className={`text-white bg-secondaryBackgroundColor border-2 border-secondaryFontColor hover:border-primaryFontColor focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 ${
                isButtonVisible ? "animate-fade-up" : "opacity-0"
              }`}
            >
              Get Started
            </a>
          </div>
          <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
        </div>
      </section>
    </>
  );
}

export default CallToAction;
