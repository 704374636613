// AboutPage.jsx
import { useEffect } from "react";
import FaqSection from "../Components/Sections/FAQ/FAQ";
import AboutCompany from "../Components/Sections/AboutCompany/AboutCompany";
import PageHeader from "../Components/Sections/PageHeader/PageHeader";
import MeetTeam from "../Components/Sections/MeetTeam/MeetTeam";
import CallToAction from "../Components/CallToAction/CallToAction";

function AboutPage() {
  // Configuration on document load
  useEffect(() => {
    document.title = "About - DinsoSoft";
  }, []);

  return (
    <div className="w-full min-h-screen bg-primaryBackgoundColor text-primaryFontColor font-sans leading-relaxed">
      <PageHeader
        title="About DinoSoft"
        desc="Pioneering the future of software development with innovation, precision, and excellence. Discover our journey, values, and the team driving change through technology."
      />
      <AboutCompany />
      <MeetTeam />
      <CallToAction />
      <FaqSection />
    
    </div>
  );
}

export default AboutPage;
