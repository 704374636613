import stockBackGroundVideo from "./stockBackgroundVideo.mp4";

function HomeHeader() {
    return (
        <div className='max-w-screen-xl mx-auto mt-0 sm:mt-5 relative h-[400px] w-full'>
            <video
                className="absolute inset-0 w-full h-full object-cover rounded-none sm:rounded-xl"
                src={stockBackGroundVideo}
                autoPlay
                muted
                loop
                playsInline
            ></video>
            <div className='relative h-full flex items-center  justify-center flex-col z-10 p-4 bg-secondaryBackgroundColor bg-opacity-50'>
                <h1 className="text-primaryFontColor text-2xl sm:text-4xl font-bold mb-2 animate-fade-left">Welcome to DinoSoft</h1>
                <p className="text-primaryFontColor text-center w-[80%] sm:w-[50%] sm:text-base animate-fade-left">We craft innovative software solutions with a commitment to unparalleled quality and timely delivery. Our dedicated team ensures that every project exceeds expectations and meets deadlines.</p>
                
                <a href="/Estimate" class="animate-fade-up animate-ease-in relative inline-flex items-center justify-center p-4 px-5 py-3 overflow-hidden font-medium text-primaryFontColor transition duration-300 ease-out rounded-full shadow-xl group ring-1 mt-4 hover:ring-2 ring-primaryFontColor">
                    <span class="relative text-white">Get Started</span>
                </a>
            </div>
        </div>
    );
}

export default HomeHeader;
