import ideaIcon from "./Icons/ideaIcon.png";
import customerIcon from "./Icons/customerIcon.png";
import technicalIcon from "./Icons/technicalIcon.png";
import scheduleIcon from "./Icons/scheduleIcon.png";
import qualityIcon from "./Icons/qualityIcon.png";
import supportIcon from "./Icons/supportIcon.png";
import "./style.css";
import useIntersectionObserver from "../../../Functions/IntersectionObserver";

const features = [
  {
    icon: ideaIcon,
    title: "Innovative Solutions",
    description:
      "Delivers cutting-edge software that addresses current and future market needs with novel technologies and improvements.",
  },
  {
    icon: customerIcon,
    title: "Customer-Centric Approach",
    description:
      "Focuses on understanding and meeting customer needs, incorporating feedback to enhance satisfaction.",
  },
  {
    icon: technicalIcon,
    title: "Technical Expertise",
    description:
      "Employs skilled professionals with up-to-date knowledge to create reliable and high-quality software.",
  },
  {
    icon: scheduleIcon,
    title: "Strong Project Management",
    description:
      "Utilizes effective methodologies to ensure timely and budget-friendly project completion.",
  },
  {
    icon: qualityIcon,
    title: "Robust Quality Assurance",
    description:
      "Conducts thorough testing to ensure software is defect-free and meets high quality standards.",
  },
  {
    icon: supportIcon,
    title: "Scalability and Support",
    description:
      "Designs scalable solutions and provides ongoing support for smooth operation and user satisfaction.",
  },
];

const HomeFeatures = () => {
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <section id="Features">
      <div className="max-w-screen-xl mx-auto mt-10 mb-10 px-5 bg-primaryBackgoundColor">
        <div className="mt-16 md:mt-0">
          <h2
            ref={titleRef}
            className={`text-4xl lg:text-4xl text-primaryFontColor font-bold lg:tracking-tight ${
              isTitleVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            Everything you need to start
          </h2>
          <p
            ref={descRef}
            className={`text-lg mt-4 text-secondaryFontColor ${
              isDescVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            Discover how our services stand out through innovative solutions,
            customer-focused approaches, and expert development.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 mt-10 gap-16">
          {features.map((feature, index) => (
            <Feature
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
        <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
      </div>
    </section>
  );
};

const Feature = ({ icon, title, description }) => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`feature-container flex gap-4 items-start bg-secondaryBackgroundColor p-5 rounded-xl hover:border-2 hover:border-secondaryFontColor animated-border 
            ${isVisible ? "animate-fade-up" : "opacity-0"}`} // Apply animation class conditionally
    >
      <div className="mt-1 bg-black rounded-full p-2 w-12 h-12 shrink-0">
        <img src={icon} alt="" />
      </div>
      <div>
        <h3 className="font-semibold text-lg text-primaryFontColor">{title}</h3>
        <p className="text-slate-500 mt-2 leading-relaxed text-secondaryFontColor hover:text-primaryFontColor">
          {description}
        </p>
      </div>
    </div>
  );
};

export default HomeFeatures;
