import { useEffect } from "react";
import useIntersectionObserver from "../Functions/IntersectionObserver";
import PageHeader from "../Components/Sections/PageHeader/PageHeader";

function ContactPage() {
  // Configuration on document load
  useEffect(() => {
    document.title = "Contact - DinsoSoft";
  }, []);

  // Form ID (which will handle the form submission)
  const formID = "xgvwyejd";

  // Open Scroll View
  const [contactInformationRef, isContactInformationVisible] =
    useIntersectionObserver({
      threshold: 0.1,
    });

  const [contactFormRef, isContactFormVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  const [locationRef, isLocationVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <section id="contact-us">
        <PageHeader
          title="Get in Touch with Us"
          desc="We are here to help you with all your software development needs."
        />
        <div
          className="max-w-screen-xl mx-auto bg-primaryBackgroundColor"
          id="contact"
        >
          <div className="flex items-stretch justify-center">
            <div className="grid md:grid-cols-2">
              <div className="h-full pr-6">
                <p className="mt-3 mb-12 text-lg text-primaryFontColor dark:text-secondaryFontColor">
                  Our team is dedicated to providing you with the best software
                  solutions and support. Whether you have a question, need a
                  quote, or want to discuss a project, feel free to reach out.
                </p>
                <ul
                  ref={contactInformationRef}
                  className={`mb-6 md:mb-0 ${
                    isContactInformationVisible
                      ? "animate-fade-right"
                      : "opacity-0"
                  }`}
                >
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-primaryHoverColor text-primaryFontColor">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="text-lg font-medium leading-6 text-primaryFontColor">
                        Our Office
                      </h3>
                      <p className="text-secondaryFontColor">
                        University of Central Punjab
                      </p>
                      <p className="text-secondaryFontColor">
                        Johar Town, Lahore, PK
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-primaryHoverColor text-primaryFontColor">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                        <path d="M15 7a2 2 0 0 1 2 2"></path>
                        <path d="M15 3a6 6 0 0 1 6 6"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="text-lg font-medium leading-6 text-primaryFontColor">
                        Contact Us
                      </h3>
                      <p className="text-secondaryFontColor">
                        Phone: +92-326-42-15322
                      </p>
                      <p className="text-secondaryFontColor">
                        Email: sheroz.akram@outlook.com
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-primaryHoverColor text-primaryFontColor">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                        <path d="M12 7v5l3 3"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="text-lg font-medium leading-6 text-primaryFontColor">
                        Office Hours
                      </h3>
                      <p className="text-secondaryFontColor">
                        Monday - Friday: 09:00 - 18:00
                      </p>
                      <p className="text-secondaryFontColor">
                        Saturday: 10:00 - 14:00
                      </p>
                      <p className="text-secondaryFontColor">Sunday: Closed</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                ref={contactFormRef}
                className={`card h-fit max-w-6xl p-5 md:p-12 ${
                  isContactFormVisible ? "animate-fade-left" : "opacity-0"
                }`}
                id="form"
              >
                <h2 className="mb-4 text-2xl font-bold text-primaryFontColor dark:text-secondaryFontColor">
                  Get in Touch with Us
                </h2>
                <form
                  id="contactForm"
                  method="POST"
                  action={`https://formspree.io/f/${formID}`}
                >
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="name"
                          className="pb-1 text-xs uppercase tracking-wider  text-secondaryFontColor"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          autoComplete="given-name"
                          placeholder="Your name"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-primaryHoverColor sm:mb-0"
                          name="name"
                          required
                        />
                      </div>
                      <div className="flex flex-wrap -mx-1 mb-1 sm:mb-4">
                        <div className="w-full sm:w-1/2 px-1 mb-1 sm:mb-0">
                          <label
                            htmlFor="email"
                            className="pb-1 text-xs uppercase tracking-wider  text-secondaryFontColor"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            autoComplete="email"
                            placeholder="Your email address"
                            className="w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-primaryHoverColor"
                            name="email"
                            required
                          />
                        </div>
                        <div className="w-full sm:w-1/2 px-1 mb-1 sm:mb-0">
                          <label
                            htmlFor="phone"
                            className="pb-1 text-xs uppercase tracking-wider  text-secondaryFontColor"
                          >
                            Phone
                          </label>
                          <input
                            type="tel"
                            id="phone"
                            autoComplete="tel"
                            placeholder="Your phone number"
                            className="w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-primaryHoverColor"
                            name="phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          htmlFor="subject"
                          className="pb-1 text-xs uppercase tracking-wider  text-secondaryFontColor"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="subject"
                          autoComplete="subject"
                          placeholder="Subject"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-primaryHoverColor sm:mb-0"
                          name="subject"
                          required
                        />
                      </div>
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        htmlFor="message"
                        className="pb-1 text-xs uppercase tracking-wider  text-secondaryFontColor"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        cols="30"
                        rows="5"
                        placeholder="Write your message..."
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-primaryHoverColor sm:mb-0"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="w-full bg-primaryHoverColor text-primaryFontColor px-6 py-3 font-xl rounded-md sm:mb-0"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={locationRef}
          className={`max-w-screen-xl mx-auto relative h-[300px] overflow-hidden bg-cover bg-center bg-no-repeat rounded-lg ${
            isLocationVisible ? "animate-fade-up" : "opacity-0"
          }`}
        >
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54450.63806302037!2d74.23576214493447!3d31.464650317695547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919015f82b0b86f%3A0x2fcaf9fdeb3d02e6!2sJohar%20Town%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1722710155634!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default ContactPage;
