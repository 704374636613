import useIntersectionObserver from "../../../Functions/IntersectionObserver";
import PropTypes from 'prop-types'; 

function PageHeader({ title, desc}) {
  // Handle On Scroll Animations
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <div className="max-w-screen-xl mx-auto mt-10 mb-10 px-5 bg-primaryBackgoundColo">
        <header className="text-center py-5">
          <h2
            ref={titleRef}
            className={`font-heading mb-4 font-bold tracking-tight text-primaryFontColor text-3xl sm:text-5xl ${
              isTitleVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            {title}
          </h2>

          <p
            ref={descRef}
            className={`mx-auto mt-4 max-w-3xl text-xl text-secondaryFontColor ${
              isDescVisible ? "animate-fade-up" : "opacity-0"
            }`}
          >
            {desc}
          </p>
        </header>
        <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
      </div>
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
}

export default PageHeader;
