import React from 'react';
import logo from "../Images/logo.png"
import addressIcon from "./addressIcon.png"
import phoneIcon from "./phoneIcon.png"
import emailIcon from "./emailIcon.png"
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <footer>
                <div className="max-w-screen-xl mx-auto mt-10 mb-10 p-14 bg-primaryBackgoundColor">
                    <div className='flex flex-col sm:flex-row justify-center text-primaryFontColor'>
                        <div class="w-full lg:w-2/5 mb-10 lg:mb-0">
                            <img src={logo} alt="" className='w-32' srcset="" />
                            <p class="my-5">
                                <Link to="/" class="mr-4 hover:text-secondaryFontColor">Home</Link>
                                <Link to="/About" class="mr-4 hover:text-secondaryFontColor">About Us</Link>
                                <Link to="/Contact" class="mr-4 hover:text-secondaryFontColor">Contact</Link>
                            </p>
                            <p class="text-gray-700 text-sm">DinoSoft Technologies © {new Date().getFullYear()}</p>
                        </div>

                        <div class="w-full lg:w-2/5 mb-10 lg:mb-0 text-base">
                            <div class="mb-6 flex">
                                <img src={addressIcon} alt="" className='w-8' srcset="" />
                                <p class="inline ml-3 hover:text-secondaryFontColor"><span>Johar Town</span> Lahore, Pakistan</p>
                            </div>
                            <div class="mb-6 flex">
                                <img src={phoneIcon} alt="" className='w-8' srcset="" />
                                <p class="inline ml-3 hover:text-secondaryFontColor"><a href="tel:+923264215322">+92-326-4215322</a></p>
                            </div>
                            <div class="mb-6 flex">
                                <img src={emailIcon} alt="" className='w-8' srcset="" />
                                <p class="inline ml-3 hover:text-secondaryFontColor"><a href="mailto:support@company.com">sheroz.akram@outlook.com</a></p>
                            </div>
                        </div>

                        <div class="footer-right w-full lg:w-1/5">
                            <p class="footer-company-about mb-6">
                                <span class="block font-bold text-2xl text-white mb-5">About the company</span>
                                Providing premium software development services with unparalleled quality at affordable prices.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;